import React, { Component } from "react";
import { Auth } from "aws-amplify";

import { Row, Col, Container, Form, Button } from "react-bootstrap";
// import MembershipDetails from "./membershipDetails";
import { Link } from "react-router-dom";
import config from "../config";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
const styles = (theme) => ({
  root: {
    display: "flex",
    marginTop: theme.spacing(3),
    overflowX: "hide",
    paddingRight: 20,
    paddingLeft: 20,
  },
  table: {
    minWidth: 340,
  },
  tableCell: {
    paddingRight: 4,
    paddingLeft: 5,
  },
});
class GhoDeliveryPackage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId10Digit: "",
      dropdownArray: [],
      packageDropdown: [],
      showData: true,
      userName: "",
      userPhoneNumber: "",
      membershipArray: [],
      userPincode: "",
      shopName: "",
      shopAddress: "",
      user: "",
      lastUpdatedby: "",
      currentDate: new Date().toISOString().substr(0, 10),
      //   lastMembershipexpiry: "",
      userInfo: {
        activationType: "paid",
      },
      lastUpdatedOn: "",
      billing: {
        userId: "",
        billingTransactionid: "",
        billingPackage: "",
        billingUpdatedBy: "",
        billingExpiryDate: "",
        activationType: "paid",
      },
      pastBillHistory: {},
      lastUpdatedby: "",
      lastUpdatedon: "",
      PreviousloyaltyRatio: "",
      billingRecords: [],
      ActivatedRadio: [],
      outletShops: [],
    };
    this.billingPackageSubmit = this.billingPackageSubmit.bind(this);

    this.getShop = this.getShop.bind(this);
    this.onChange = this.onChange.bind(this);
    this.reset = this.state;

    this.handleOnChange = this.handleOnChange.bind(this);
  }
  //   componentDidUpdate() {
  //     window.onpopstate = e => {
  //       e.preventDefault();
  //       if (window.confirm("are you sure want to leave the page ?")) {
  //         window.location.assign("/");
  //       } else {
  //         window.location.assign("/loyalty");
  //       }
  //     };
  //   }
  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleOnChange(e) {
    this.setState((prev) => ({
      userInfo: {
        ...prev.userInfo,
        [e.target.name]: e.target.value,
      },
    }));
  }

  componentDidMount() {
    Auth.currentAuthenticatedUser({
      bypassCache: true, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then((data) => {
        let user = { email: data.email, ...data.attributes };

        this.setState({
          ...this.state,
          billing: {
            ...this.state.billing,
            billingUpdatedBy: user.phone_number,
          },
        });
      })

      .catch((err) => console.log(err));
  }

  getShop(e) {
    e.preventDefault();
    fetch(`${config.ApiGateway}/getshop`, {
      method: "POST",
      body: JSON.stringify({
        userId10Digit: this.state.userId10Digit,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        const shopData = data.Items?.[0];
        if (shopData?.shopType === "Aggregated") {
          this.setState({
            showData: false,
            userInfo: shopData,
          });
        } else {
          alert("This is not a Gho Delivery Shop");
        }
      })
      .catch((err) => {
        this.setState({
          message: "NO SHOPS FOUND",
        });
      });
  }
  billingPackageSubmit(e) {
    e.preventDefault();
    fetch(`${config.ApiGateway}/billingPackageUpdate`, {
      method: "POST",
      body: JSON.stringify({
        type: "updateGhoDeliveryPackage",
        shop: this.state.userInfo,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        alert(
          `${data.status} ${
            this.state.billing.activationType == "paid"
              ? "  & Bill Generated"
              : ""
          }`
        );
        this.setState(this.reset);
        this.componentDidMount();
      })
      .catch((err) => console.log(err));
  }

  render() {
    const { classes } = this.props;
    return (
      <Container fluid>
        <Row className="rowClass">
          <b style={{ fontSize: 32 }}>Ghodelivery Package</b>
        </Row>
        {this.state.showData ? (
          <>
            <Form onSubmit={this.getShop}>
              <Row style={{ marginTop: 10 + "%" }}>
                <Col xs={{ span: 4, offset: 4 }}>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Enter Shop ID</Form.Label>
                    <Form.Control
                      type="text"
                      name="userId10Digit"
                      value={this.state.userId10Digit}
                      onChange={this.onChange}
                    ></Form.Control>
                  </Form.Group>
                  <Button
                    style={{
                      width: 100 + "%",
                      height: 30 + "%",
                      marginTop: 2 + "%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    type="submit"
                  >
                    {" "}
                    SEARCH
                  </Button>
                </Col>
              </Row>
            </Form>
          </>
        ) : (
          <>
            <Row className="rowClass">
              <Col className="division" xs={5}>
                <>
                  <div className="rowClass">
                    <h5>
                      {" "}
                      <b style={{ fontSize: 18 }}>Shop Details</b>
                    </h5>
                  </div>

                  <Form.Group as={Row} controlId="formPlaintextEmail">
                    <Form.Label column>Shop Name</Form.Label>
                    <Col xs={6}>
                      <Form.Control
                        disabled
                        value={this.state.userInfo["shopName"]}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="formPlaintextEmail">
                    <Form.Label column>User ID</Form.Label>
                    <Col xs={6}>
                      <Form.Control
                        disabled
                        value={this.state.userInfo["userId10Digit"]}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="formPlaintextEmail">
                    <Form.Label column>Name</Form.Label>
                    <Col>
                      <Form.Control
                        disabled
                        value={this.state.userInfo["userName"]}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="formPlaintextEmail">
                    <Form.Label column>Pincode</Form.Label>
                    <Col>
                      <Form.Control
                        disabled
                        value={this.state.userInfo["userPincode"]}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="formPlaintextEmail">
                    <Form.Label column>Phone Number</Form.Label>
                    <Col>
                      <Form.Control
                        disabled
                        value={this.state.userInfo["userPhoneNumber"]}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} controlId="formPlaintextEmail">
                    <Form.Label column>Shop Type</Form.Label>
                    <Col>
                      <Form.Control
                        disabled
                        value={this.state.userInfo["shopType"]}
                      />
                    </Col>
                  </Form.Group>
                </>
              </Col>
              <Col className="division" xs={5}>
                <div className="rowClass">
                  <h5>
                    {" "}
                    <b style={{ fontSize: 18 }}>Package Details</b>
                  </h5>
                </div>
                <Form onSubmit={this.billingPackageSubmit}>
                  <>
                    <Form.Group as={Row}>
                      <Form.Label column>
                        Activate Gho Delivery Package
                      </Form.Label>

                      <Col xs={3}>
                        <Form.Check
                          type="radio"
                          name="ghoDeliveryPackageActive"
                          onChange={this.handleOnChange}
                          label={"YES"}
                          value="yes"
                          checked={this.state.userInfo.ghoDeliveryPackageActive}
                        />
                      </Col>
                      <Col xs={3}>
                        <Form.Check
                          type="radio"
                          name="ghoDeliveryPackageActive"
                          onChange={this.handleOnChange}
                          label={"NO"}
                          value="no"
                          checked={
                            this.state.userInfo.ghoDeliveryPackageActive ===
                            false
                          }
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formPlaintextEmail">
                      <Form.Label column>Gho Delivery Start Date</Form.Label>
                      <Col>
                        <Form.Control
                          type="date"
                          name="ghoDeliveryPackageStartDate"
                          onChange={this.handleOnChange}
                          value={
                            this.state.userInfo?.ghoDeliveryPackageStartDate
                          }
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formPlaintextEmail">
                      <Form.Label column>Gho Delivery Expiry Date</Form.Label>
                      <Col>
                        <Form.Control
                          type="date"
                          name="ghoDeliveryPackageExpiryDate"
                          onChange={this.handleOnChange}
                          value={
                            this.state.userInfo?.ghoDeliveryPackageExpiryDate
                          }
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formPlaintextEmail">
                      <Form.Label column>Activation Type</Form.Label>
                      <Col>
                        <Form.Control
                          as="select"
                          name="activationType"
                          onChange={this.handleOnChange}
                          value={this.state.userInfo.activationType}
                        >
                          <option value="free">Free</option>
                          <option value="paid">Paid</option>
                        </Form.Control>
                      </Col>
                    </Form.Group>
                    <div className="btnalignright">
                      <Button type="submit">Submit</Button>
                    </div>
                  </>
                </Form>
              </Col>
            </Row>
          </>
        )}
      </Container>
    );
  }
}
GhoDeliveryPackage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GhoDeliveryPackage);
