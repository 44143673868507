import React, { Component } from "react";
import { Auth } from "aws-amplify";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TeleCalling from "./telecalling";
import { Link } from "react-router-dom";
import DataEntry from "./dataEntry";
import { List, ListItem, ListItemText } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Administrator from "./Administrator";
import SalesManager from "./salesManager";
import ViewOffer from "./viewOffer";
import Sales from "./Sales";
import ManagingDirector from "./managingDirector";
import Partner from "./partner";
import Franchise from "./franchise";

export default class Mainpage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: [],
      packageUpdate: false,
      couponToggle: false,
      reachUsInbox: false,
      bannertoggle: false,
      createoffertoggle: false,
      pincodeDistributionToggle: false,
      validityDateToggle: false,
      appointmentToggle: false,
      billingToggle: false,
      deliveryToggle: false,
      distribution: false,
      payment: false,
      telecalling: false,
      ownDomainToggle: false,
      license: false,
    };

    this.handleClick = this.handleClick.bind(this);
  }

  async componentDidMount() {
    await Auth.currentAuthenticatedUser().then((user) => {
      var info = user.signInUserSession.idToken.payload["cognito:groups"];
      var restrictUser = info === undefined ? "sales" : info[0];
      var fetchField = [];
      var object;
      switch (restrictUser || this.props.userRole) {
        case "adminstrator":
          object = new Administrator();
          fetchField = object.fields;
          break;
        case "managingDirector":
          object = new ManagingDirector();
          fetchField = object.fields;
          break;
        case "partner":
          object = new Partner();
          fetchField = object.fields;
          break;
        case "sales":
          object = new Sales();
          fetchField = object.fields;
          break;
        case "salesManager":
          object = new SalesManager();
          fetchField = object.fields;
          break;
        case "dataEntry":
          object = new DataEntry();
          fetchField = object.fields;
          break;
        case "viewOffer":
          object = new ViewOffer();
          fetchField = object.fields;
          break;
        case "franchise":
          object = new Franchise();
          fetchField = object.fields;
          break;
        case "teleCalling":
          object = new TeleCalling();
          fetchField = object.fields;
          break;
        default:
          object = new Sales();
          fetchField = object.fields;
          break;
      }

      this.setState({
        fields: fetchField, // list component in ui based on user role.
      });
    });
  }

  handleClick = (check) => {
    // Toggle list Dropdown
    switch (check) {
      case "couponToggle":
        {
          this.setState({
            couponToggle: !this.state.couponToggle,
          });
        }
        return;
      case "appointmentToggle":
        {
          this.setState({
            appointmentToggle: !this.state.appointmentToggle,
          });
        }
        return;
      case "reachUsInbox":
        {
          this.setState({
            reachUsInbox: !this.state.reachUsInbox,
          });
        }
        return;
      case "packageUpdate":
        {
          this.setState({
            packageUpdate: !this.state.packageUpdate,
          });
        }
        return;

      case "bannertoggle":
        {
          this.setState({
            bannertoggle: !this.state.bannertoggle,
          });
        }
        return;
      case "createoffertoggle":
        {
          this.setState({
            createoffertoggle: !this.state.createoffertoggle,
          });
        }
        return;
      case "distribution":
        {
          this.setState({
            distribution: !this.state.distribution,
          });
        }
        return;
      case "pincodeDistributionToggle":
        {
          this.setState({
            pincodeDistributionToggle: !this.state.pincodeDistributionToggle,
          });
        }
        return;

      case "billingToggle":
        {
          this.setState({
            billingToggle: !this.state.billingToggle,
          });
        }
        return;
      case "deliveryToggle":
        {
          this.setState({
            deliveryToggle: !this.state.deliveryToggle,
          });
        }
        return;

      case "extendMerchantCoupon":
        {
          this.setState({
            validityDateToggle: !this.state.validityDateToggle,
          });
        }
        return;
      case "paymentToggle":
        {
          this.setState({
            payment: !this.state.payment,
          });
        }
        return;
      case "licenseToggle": {
        this.setState({
          license: !this.state.license,
        });
      }
      case "teleCallingToggle":
        {
          this.setState({
            telecalling: !this.state.telecalling,
          });
        }
        return;
      case "ownDomain":
        {
          this.setState({
            ownDomainToggle: !this.state.ownDomainToggle,
          });
        }
        return;
      default:
        return <></>;
    }
  };

  render() {
    return (
      <>
        {this.props.authState === "signedIn" && ( //If user is logged in display page
          <>
            <div className="mainContainer">
              <Container fluid>
                <Row>
                  <Col md={{ span: 3 }}>
                    <List
                      component="nav"
                      aria-labelledby="nested-list-subheader"
                      style={{ width: "100%", maxWidth: "360px" }}
                    >
                      {this.state.fields.includes("dashboard") && (
                        <>
                          <ListItem button component={Link} to="/dashboard">
                            <ListItemText primary="DASHBOARD" />
                          </ListItem>
                          <ListItem
                            button
                            component={Link}
                            to="/customerinsight"
                          >
                            <ListItemText primary="CUSTOMER INSIGHT" />
                          </ListItem>
                          <ListItem
                            button
                            component={Link}
                            to="/ghoDeliveryInsight"
                          >
                            <ListItemText primary="GHO-DELIVERY INSIGHT" />
                          </ListItem>
                          <ListItem
                            button
                            component={Link}
                            to="/marketingCampaign"
                          >
                            <ListItemText primary="MARKETING CAMPAIGN" />
                          </ListItem>
                          <ListItem button component={Link} to="/merchantcrm">
                            <ListItemText primary="MERCHANT CRM" />
                          </ListItem>
                          <ListItem
                            button
                            component={Link}
                            to="/paymentGateWays"
                          >
                            <ListItemText primary="PAYMENT GATEWAYS" />
                          </ListItem>
                        </>
                      )}

                      {this.state.fields.includes(
                        "managingDirectorprofile"
                      ) && (
                        <ListItem
                          button
                          component={Link}
                          to="/managingDirector/home"
                        >
                          <ListItemText primary="MANAGING DIRECTOR" />
                        </ListItem>
                      )}
                      {this.state.fields.includes("partner") && (
                        <ListItem button component={Link} to="/partner/home">
                          <ListItemText primary="HOME" />
                        </ListItem>
                      )}

                      {this.state.fields.includes("review") && (
                        <>
                          <ListItem button component={Link} to="/review">
                            <ListItemText primary="CONTENT REVIEW" />
                          </ListItem>
                        </>
                      )}

                      {this.state.fields.includes("licenseManagement") && (
                        <>
                          <ListItem
                            button
                            component={Link}
                            to="/licenseManagement"
                          >
                            <ListItemText primary="LICENSE MANAGEMENT" />
                          </ListItem>
                        </>
                      )}

                      <ListItem button component={Link} to="/resellers">
                        <ListItemText primary="RESELLERS" />
                      </ListItem>

                      {this.state.fields.includes("userAdded") && (
                        <>
                          <ListItem button component={Link} to="/userAdded">
                            <ListItemText primary="USER ADDED" />
                          </ListItem>
                        </>
                      )}
                      {this.state.fields.includes("telecalling") && (
                        <>
                          <ListItem
                            button
                            onClick={() =>
                              this.handleClick("teleCallingToggle")
                            }
                          >
                            <ListItemText primary="TELECALLING" />
                            {this.state.telecalling ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )}
                          </ListItem>
                          <Collapse
                            in={this.state.telecalling}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List component="div" disablePadding>
                              <ListItem
                                button
                                component={Link}
                                to="/telecallinghome"
                              >
                                <ListItemText primary="Shops" />
                              </ListItem>
                              {/* <ListItem
                                button
                                component={Link}
                                to="/telecalling/dashboard"
                              >
                                <ListItemText primary="Dashboard" />
                              </ListItem> */}
                              <ListItem
                                button
                                component={Link}
                                to="/telecallingdashboard"
                              >
                                <ListItemText primary="Dashboard" />
                              </ListItem>
                            </List>
                          </Collapse>
                        </>
                      )}
                      {this.state.fields.includes("contentupload") && (
                        <ListItem button component={Link} to="/contentUpload">
                          <ListItemText primary="Content Upload" />
                        </ListItem>
                      )}
                      {this.state.fields.includes("gstore") && (
                        <>
                          <ListItem
                            button
                            component={Link}
                            to="/gstoreActivation"
                          >
                            <ListItemText primary="GSTORE ACTIVATION" />
                          </ListItem>
                          <ListItem
                            button
                            component={Link}
                            to="/viewWebsiteStatus"
                          >
                            <ListItemText primary="WEBSITE STATUS TRACKER" />
                          </ListItem>
                          <ListItem
                            button
                            to="/ownDomainTracker"
                            onClick={() => this.handleClick("ownDomain")}
                          >
                            <ListItemText primary="OWN DOMAIN TRACKER" />
                            {this.state.telecalling ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )}
                          </ListItem>
                          <Collapse
                            in={this.state.ownDomainToggle}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List component="div" disablePadding>
                              <ListItem
                                button
                                component={Link}
                                to="/ownDomainTracker"
                              >
                                <ListItemText primary="Own Domain Tracker" />
                              </ListItem>
                              <ListItem
                                button
                                component={Link}
                                to="/contentUpload"
                              >
                                <ListItemText primary="Content Upload" />
                              </ListItem>
                            </List>
                          </Collapse>
                        </>
                      )}

                      {this.state.fields.includes("telecalling") && (
                        <>
                          <ListItem
                            button
                            component={Link}
                            to="/deliveryPartner"
                          >
                            <ListItemText primary="DELIVERY PARTNER MANAGEMENT" />
                          </ListItem>
                        </>
                      )}

                      {this.state.fields.includes("offerCreation") && (
                        <>
                          <ListItem button component={Link} to="/offersList">
                            <ListItemText primary="OFFER CREATION" />
                          </ListItem>
                        </>
                      )}
                      {this.state.fields.includes("customerManagement") && (
                        <>
                          <ListItem
                            button
                            component={Link}
                            to="/customerManagement"
                          >
                            <ListItemText primary="CUSTOMER MANAGEMENT" />
                          </ListItem>
                        </>
                      )}
                      {this.state.fields.includes("merchantManagement") && (
                        <>
                          <ListItem
                            button
                            component={Link}
                            to="/merchantManagement"
                          >
                            <ListItemText primary="MERCHANT MANAGEMENT" />
                          </ListItem>
                          <ListItem button component={Link} to="/addemployee">
                            <ListItemText primary="ADD EMPLOYEE" />
                          </ListItem>
                        </>
                      )}
                      {this.state.fields.includes("mac") && (
                        <>
                          <ListItem button component={Link} to="/mac">
                            <ListItemText primary="MAC ID" />
                          </ListItem>
                        </>
                      )}
                      {this.state.fields.includes("referal") && (
                        <>
                          <ListItem button component={Link} to="/referal">
                            <ListItemText primary="REFERRAL" />
                          </ListItem>
                        </>
                      )}
                      {this.state.fields.includes("salestarget") && (
                        <>
                          <ListItem button component={Link} to="/salestarget">
                            <ListItemText primary="SALES TARGET" />
                          </ListItem>
                          <ListItem button component={Link} to="/salesrefferal">
                            <ListItemText primary="SALES REFFERAL" />
                          </ListItem>
                        </>
                      )}
                      {this.state.fields.includes("pincodeSynonyms") && (
                        <>
                          <ListItem
                            button
                            component={Link}
                            to="/pincodeSynonyms"
                          >
                            <ListItemText primary="PINCODE SYNONYMS" />
                          </ListItem>
                        </>
                      )}
                      {this.state.fields.includes("deleteAccount") && (
                        <>
                          <ListItem button component={Link} to="/deleteAccount">
                            <ListItemText primary="DELETE ACCOUNT" />
                          </ListItem>
                        </>
                      )}

                      {this.state.fields.includes("crm") && (
                        <>
                          {/* <ListItem button component={Link} to="/merchant">
                            <ListItemText primary="MERCHANT CRM" />
                          </ListItem> */}
                          <ListItem button component={Link} to="/customer">
                            <ListItemText primary="CUSTOMER CRM" />
                          </ListItem>
                        </>
                      )}
                      {this.state.fields.includes("getExpiration") && (
                        <>
                          <ListItem button component={Link} to="/getExpiration">
                            <ListItemText primary="PACKAGE EXPIRATION" />
                          </ListItem>
                        </>
                      )}
                      {this.state.fields.includes("promoteoffers") && (
                        <>
                          <ListItem button component={Link} to="/promoteoffers">
                            <ListItemText primary="PROMOTE OFFERS" />
                          </ListItem>
                        </>
                      )}
                      {this.state.fields.includes("couponpayment") && (
                        <>
                          <ListItem
                            button
                            onClick={() => this.handleClick("paymentToggle")}
                          >
                            <ListItemText primary="PAYMENTS" />
                            {this.state.payment ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )}
                          </ListItem>
                          <Collapse
                            in={this.state.payment}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List component="div" disablePadding>
                              <ListItem
                                button
                                component={Link}
                                to="/ghoshakTransaction"
                              >
                                <ListItemText primary="Ghoshak Transaction" />
                              </ListItem>
                              <ListItem
                                button
                                component={Link}
                                to="/couponpayment"
                              >
                                <ListItemText primary="Coupon Payment" />
                              </ListItem>
                            </List>
                          </Collapse>
                        </>
                      )}
                      {this.state.fields.includes("ghoshakgift") && (
                        <>
                          <ListItem button component={Link} to="/ghoshakgift">
                            <ListItemText primary="GHOSHAK GIFT" />
                          </ListItem>
                        </>
                      )}
                      {this.state.fields.includes("phonenumber") && (
                        <>
                          <ListItem button component={Link} to="/getnumber">
                            <ListItemText primary="GET PHONE NUMBER" />
                          </ListItem>
                          <ListItem button component={Link} to="/getid">
                            <ListItemText primary="GET CUSTOMER ID" />
                          </ListItem>
                        </>
                      )}
                      {this.state.fields.includes("notification") && (
                        <>
                          <ListItem button component={Link} to="/notification">
                            <ListItemText primary="SEND NOTIFICATION" />
                          </ListItem>
                        </>
                      )}

                      {this.state.fields.includes("appointment") && (
                        <>
                          <ListItem
                            button
                            onClick={() =>
                              this.handleClick("appointmentToggle")
                            }
                          >
                            <ListItemText primary="APPOINTMENT" />
                            {this.state.appointmentToggle ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )}
                          </ListItem>
                          <Collapse
                            in={this.state.appointmentToggle}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List component="div" disablePadding>
                              <ListItem
                                button
                                component={Link}
                                to="/activateAppointment"
                              >
                                <ListItemText primary="Activate Appointment" />
                              </ListItem>

                              <ListItem
                                button
                                component={Link}
                                to="/extendAppointment"
                              >
                                <ListItemText primary="Extend Appointment" />
                              </ListItem>

                              <ListItem
                                button
                                component={Link}
                                to="/appointmentDashboard"
                              >
                                <ListItemText primary="Dashboard" />
                              </ListItem>

                              <ListItem
                                button
                                component={Link}
                                to="/appointmentPackageUsers"
                              >
                                <ListItemText primary="Users" />
                              </ListItem>
                            </List>
                          </Collapse>
                        </>
                      )}

                      {this.state.fields.includes("delivery") && (
                        <>
                          <ListItem
                            button
                            onClick={() => this.handleClick("deliveryToggle")}
                          >
                            <ListItemText primary="DELIVERY" />
                            {this.state.deliveryToggle ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )}
                          </ListItem>
                          <Collapse
                            in={this.state.deliveryToggle}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List component="div" disablePadding>
                              <ListItem
                                button
                                component={Link}
                                to="/addproduct"
                              >
                                <ListItemText primary="Add Products" />
                              </ListItem>
                              {/* <ListItem
                                button
                                component={Link}
                                to="/inventoryProductsByShop"
                              >
                                <ListItemText primary="O Shop Inventory Products" />
                              </ListItem> */}
                              <ListItem
                                button
                                component={Link}
                                to="/shopaddeddelivery"
                              >
                                <ListItemText primary="Add products to shop" />
                              </ListItem>

                              <ListItem
                                button
                                component={Link}
                                to="/onlineOrders"
                              >
                                <ListItemText primary="Orders" />
                              </ListItem>

                              <ListItem
                                button
                                component={Link}
                                to="/onlineDashboard"
                              >
                                <ListItemText primary="Dashboard" />
                              </ListItem>

                              <ListItem
                                button
                                component={Link}
                                to="/paymentTracker"
                              >
                                <ListItemText primary="Payment Tracker" />
                              </ListItem>
                              <ListItem
                                button
                                component={Link}
                                to="/shopInventoryProducts"
                              >
                                <ListItemText primary="N Shop Inventory Products" />
                              </ListItem>
                            </List>
                          </Collapse>
                        </>
                      )}

                      {this.state.fields.includes("extendValidityDate") && (
                        <>
                          <ListItem
                            button
                            onClick={() =>
                              this.handleClick("extendMerchantCoupon")
                            }
                          >
                            <ListItemText primary="EXTEND VALIDITY DATE" />
                            {this.state.validityDateToggle ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )}
                          </ListItem>
                          <Collapse
                            in={this.state.validityDateToggle}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List component="div" disablePadding>
                              <ListItem
                                button
                                component={Link}
                                to="/extendMerchantCoupon"
                              >
                                <ListItemText primary="EXTEND MERCHANT COUPON" />
                              </ListItem>
                              <ListItem
                                button
                                component={Link}
                                to="/extendOfferEndDate"
                              >
                                <ListItemText primary="EXTEND OFFER END DATE" />
                              </ListItem>

                              <ListItem
                                button
                                component={Link}
                                to="/extendMembership"
                              >
                                <ListItemText primary="EXTEND MEMBERSHIP" />
                              </ListItem>

                              <ListItem
                                button
                                component={Link}
                                to="/extendLoyalty"
                              >
                                <ListItemText primary="EXTEND LOYALTY" />
                              </ListItem>
                            </List>
                          </Collapse>
                        </>
                      )}
                      {this.state.fields.includes("coupons") && (
                        <>
                          <ListItem
                            button
                            onClick={() => this.handleClick("couponToggle")}
                          >
                            <ListItemText primary="COUPONS" />
                            {this.state.couponToggle ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )}
                          </ListItem>
                          <Collapse
                            in={this.state.couponToggle}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List component="div" disablePadding>
                              <ListItem button component={Link} to="/addcoupon">
                                <ListItemText primary="Add Coupon" />
                              </ListItem>
                              <ListItem
                                button
                                component={Link}
                                to="/onlineCoupons"
                              >
                                <ListItemText primary="Online Coupons" />
                              </ListItem>
                              <ListItem
                                button
                                component={Link}
                                to="/viewOnlineCoupons"
                              >
                                <ListItemText primary="View Online Coupons" />
                              </ListItem>
                              <ListItem
                                button
                                component={Link}
                                to="/redeemPromo"
                              >
                                <ListItemText primary="Redeem Promocode" />
                              </ListItem>
                              <ListItem
                                button
                                component={Link}
                                to="/editcoupon"
                              >
                                <ListItemText primary="Edit Coupon" />
                              </ListItem>
                              <ListItem
                                button
                                component={Link}
                                to="/deletecoupon"
                              >
                                <ListItemText primary="Delete Coupon" />
                              </ListItem>
                            </List>
                          </Collapse>
                        </>
                      )}
                      {this.state.fields.includes("reachus") && (
                        <>
                          <ListItem button component={Link} to="/reachus">
                            <ListItemText primary="REACH US INBOX" />
                          </ListItem>
                        </>
                      )}

                      {this.state.fields.includes("addGhoshakCoins") && (
                        <ListItem button component={Link} to="/addGhoshakCoins">
                          <ListItemText primary="ADD GHOSHAK COINS" />
                        </ListItem>
                      )}

                      {this.state.fields.includes("package") && (
                        <>
                          <ListItem
                            button
                            onClick={() => this.handleClick("packageUpdate")}
                          >
                            <ListItemText primary="PACKAGES" />
                            {this.state.packageUpdate ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )}
                          </ListItem>
                          <Collapse
                            in={this.state.packageUpdate}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List component="div" disablePadding>
                              <ListItem
                                button
                                component={Link}
                                to="/gho-delivery-package"
                              >
                                <ListItemText primary="GHO DELIVERY PACKAGE" />
                              </ListItem>
                              <ListItem
                                button
                                component={Link}
                                to="/membership"
                              >
                                <ListItemText primary="MEMBERSHIP" />
                              </ListItem>
                              <ListItem
                                button
                                component={Link}
                                to="/generateinvoice"
                              >
                                <ListItemText primary="GENERATE INVOICE" />
                              </ListItem>
                              <ListItem button component={Link} to="/loyalty">
                                <ListItemText primary="ACTIVATE LOYALTY" />
                              </ListItem>
                              <ListItem button component={Link} to="/package">
                                <ListItemText primary="PACKAGE DETAILS" />
                              </ListItem>

                              <ListItem
                                button
                                component={Link}
                                to="/setloyalty"
                              >
                                <ListItemText primary="SET LOYALTY POINTS" />
                              </ListItem>
                            </List>
                          </Collapse>
                        </>
                      )}
                      {this.state.fields.includes("banner") && (
                        <>
                          <ListItem
                            button
                            onClick={() => this.handleClick("bannertoggle")}
                          >
                            <ListItemText primary="BANNER" />
                            {this.state.bannertoggle ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )}
                          </ListItem>
                          <Collapse
                            in={this.state.bannertoggle}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List component="div" disablePadding>
                              <ListItem button component={Link} to="/addbanner">
                                <ListItemText primary="CUSTOMER ADD BANNER" />
                              </ListItem>
                              <ListItem
                                button
                                component={Link}
                                to="/merchantbanner"
                              >
                                <ListItemText primary="MERCHANT ADD BANNER" />
                              </ListItem>
                              <ListItem
                                button
                                component={Link}
                                to="/editbanner"
                              >
                                <ListItemText primary="EDIT BANNER" />
                              </ListItem>
                              <ListItem button component={Link} to="/getBanner">
                                <ListItemText primary="BANNER LOCATION" />
                              </ListItem>
                            </List>
                          </Collapse>
                        </>
                      )}

                      {this.state.fields.includes("billing") && (
                        <>
                          <ListItem
                            button
                            onClick={() => this.handleClick("billingToggle")}
                          >
                            <ListItemText primary="BILLING" />
                            {this.state.billingToggle ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )}
                          </ListItem>
                          <Collapse
                            in={this.state.billingToggle}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List component="div" disablePadding>
                              <ListItem
                                button
                                component={Link}
                                to="/activateBilling"
                              >
                                <ListItemText primary="Activate billing" />
                              </ListItem>
                              <ListItem
                                button
                                component={Link}
                                to="/activateGhoDelivery"
                              >
                                <ListItemText primary="Activate ghodelivery" />
                              </ListItem>
                              <ListItem
                                button
                                component={Link}
                                to="/extendSmsPackage"
                              >
                                <ListItemText primary="Extend SMS" />
                              </ListItem>
                              <ListItem
                                button
                                component={Link}
                                to="/extendBilling"
                              >
                                <ListItemText primary="Extend Billing" />
                              </ListItem>

                              <ListItem
                                button
                                component={Link}
                                to="/billingProgram"
                              >
                                <ListItemText primary="Billing Program" />
                              </ListItem>
                            </List>
                          </Collapse>
                        </>
                      )}

                      {this.state.fields.includes("createoffer") && (
                        <>
                          <ListItem
                            button
                            onClick={() =>
                              this.handleClick("createoffertoggle")
                            }
                          >
                            <ListItemText primary="CREATE OFFERS" />
                            {this.state.createoffertoggle ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )}
                          </ListItem>
                          <Collapse
                            in={this.state.createoffertoggle}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List component="div" disablePadding>
                              <ListItem
                                button
                                component={Link}
                                to="/createoffer"
                              >
                                <ListItemText primary="OFFERS" />
                              </ListItem>
                              <ListItem
                                button
                                component={Link}
                                to="/createitem"
                              >
                                <ListItemText primary="COLLECTION" />
                              </ListItem>
                            </List>
                          </Collapse>
                        </>
                      )}

                      {this.state.fields.includes("pincodeDistribution") && (
                        <>
                          <ListItem
                            button
                            onClick={() =>
                              this.handleClick("pincodeDistributionToggle")
                            }
                          >
                            <ListItemText primary="PINCODE DISTRIBUTION" />
                            {this.state.pincodeDistributionToggle ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )}
                          </ListItem>
                          <Collapse
                            in={this.state.pincodeDistributionToggle}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List component="div" disablePadding>
                              <ListItem
                                button
                                component={Link}
                                to="/offerdistributon"
                              >
                                <ListItemText primary="OFFER DISTRIBUTION" />
                              </ListItem>
                              <ListItem
                                button
                                component={Link}
                                to="/itemdistribution"
                              >
                                <ListItemText primary="COLLECTION" />
                              </ListItem>
                              <ListItem
                                button
                                component={Link}
                                to="/distOfCollectionCategory"
                              >
                                <ListItemText primary="COLLECTION CATEGORY" />
                              </ListItem>

                              <ListItem
                                button
                                component={Link}
                                to="/billingDashboard"
                              >
                                <ListItemText primary="BILLING" />
                              </ListItem>

                              <ListItem
                                button
                                component={Link}
                                to="/segmentdistribution"
                              >
                                <ListItemText primary="SEGMENT" />
                              </ListItem>
                              <ListItem
                                button
                                component={Link}
                                to="/customerDistribution"
                              >
                                <ListItemText primary="CUSTOMER" />
                              </ListItem>
                              <ListItem
                                button
                                component={Link}
                                to="/promocodedistribution"
                              >
                                <ListItemText primary="PROMOCODE" />
                              </ListItem>
                              <ListItem
                                button
                                component={Link}
                                to="/merchantCouponDistributionByPincode"
                              >
                                <ListItemText primary="MERCHANT COUPON" />
                              </ListItem>
                              <ListItem
                                button
                                component={Link}
                                to="/ghoshakCouponByPincode"
                              >
                                <ListItemText primary="GHOSHAK COUPON" />
                              </ListItem>
                              <ListItem
                                button
                                component={Link}
                                to="/loyaltyCouponDistributionByPincode"
                              >
                                <ListItemText primary="LOYALTY COUPON" />
                              </ListItem>
                            </List>
                          </Collapse>
                        </>
                      )}

                      {this.state.fields.includes("distribution") && (
                        <>
                          <ListItem
                            button
                            onClick={() => this.handleClick("distribution")}
                          >
                            <ListItemText primary="DISTRIBUTION" />
                            {this.state.distribution ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )}
                          </ListItem>
                          <Collapse
                            in={this.state.distribution}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List component="div" disablePadding>
                              <ListItem button component={Link} to="/promocode">
                                <ListItemText primary="PROMOCODE DISTRIBUTION BY PROMOCODE" />
                              </ListItem>
                              <ListItem button component={Link} to="/ghoshak">
                                <ListItemText primary="GHOSHAK COUPON" />
                              </ListItem>

                              <ListItem
                                button
                                component={Link}
                                to="/merchantDistribution"
                              >
                                <ListItemText primary="MERCHANT COUPON" />
                              </ListItem>
                              <ListItem
                                button
                                component={Link}
                                to="/LoyaltyDistribution"
                              >
                                <ListItemText primary="LOYALTY COUPON" />
                              </ListItem>
                            </List>
                          </Collapse>
                        </>
                      )}
                      {this.state.fields.includes("gamification") && (
                        <>
                          <ListItem button component={Link} to="/gamification">
                            <ListItemText primary="GAMIFICATION" />
                          </ListItem>
                        </>
                      )}
                      {this.state.fields.includes("payment") && (
                        <>
                          <ListItem button component={Link} to="/payment">
                            <ListItemText primary="PACKAGE PAYMENT" />
                          </ListItem>
                        </>
                      )}
                    </List>
                  </Col>
                  <Col
                    style={{ padding: "0", margin: "0px" }}
                    md={{ span: 8 }}
                  ></Col>
                </Row>
              </Container>
            </div>
          </>
        )}
      </>
    );
  }
}
