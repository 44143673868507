import React, { useState, useRef, useEffect } from "react";
import { Table, Popover } from "antd";
import config from "../config";
import "../styles/ghoDeliveryInsight.css";
import Modal from "react-modal";
import ShowMoreText from "react-show-more-text";
import { DateRangePicker } from "react-date-range";
import { addDays, format } from "date-fns";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { RxReload } from "react-icons/rx";
import { GrFormClose } from "react-icons/gr";
import Fuse from "fuse.js";
import { HiMagnifyingGlass } from "react-icons/hi2";
const GhoDeliveryInsight = () => {
  const [loading, setLoading] = useState(false);
  const [insightDetails, setInsightDetails] = useState([]);
  const [insightDetailsBackUp, setInsightDetailsBackUp] = useState([]);

  const [sellerDetailsModal, setSellerDetailsModal] = useState(false);
  const [sellerDetails, setSellerDetails] = useState({});
  const [currentShopName, setCurrentShopName] = useState("");

  const [selectionRange, setSelectionRange] = useState({
    startDate: addDays(new Date(), -15),
    endDate: new Date(),
    key: "selection",
  });

  const [tempRange, setTempRange] = useState({
    startDate: addDays(new Date(), -15),
    endDate: new Date(),
    key: "selection",
  });

  const getInsight = async () => {
    setLoading(true);

    try {
      let response = await fetch(
        `${config.POSApi}/generateCustomerBillReport`,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            getGhoDeliveryInsight: true,
            fromDate: selectionRange.startDate
              ? format(selectionRange.startDate, "yyyy-MM-dd")
              : "",
            toDate: selectionRange.endDate
              ? format(selectionRange.endDate, "yyyy-MM-dd")
              : "",
          }),
        }
      );
      response = await response.json();

      console.log("response", response);
      if (response.statusCode === 200) {
        let url =
          "https://prod-ghoshak.s3.ap-south-1.amazonaws.com/ghoDelivery_customers_report.JSON";
        await getDatabill(url);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getDatabill = async (url) => {
    await Promise.all([
      fetch(url, {
        headers: {
          "Content-Type": "application/json",
        },
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(async ([data]) => {
        console.log("data", data);
        let temp = data.data.sort((a, b) => {
          if (a.Sellers.length > b.Sellers.length) return -1;
          if (a.Sellers.length < b.Sellers.length) return 1;
          if (a.Sellers.length == b.Sellers.length) {
            return 0;
          }
        });
        setInsightDetails([...temp]);
        setInsightDetailsBackUp([...temp]);
        setLoading(false);
        return;
      })
      .catch((err) => console.log(err));
  };

  const sellerDetailsModalStyles = {
    content: {
      position: "absolute",
      inset: "40px",
      border: "1px solid rgb(204, 204, 204)",
      background: "rgb(255, 255, 255)",
      overflow: "auto",
      borderRadius: "4px",
      outline: "none",
      padding: "20px",
      overlay: {
        background: "#f6f6f6",
      },
    },
  };

  const tableHeaderStyle = {
    fontSize: "16px",
    color: "black",
    // fontFamily: "s",
    textAlign: "center",
  };
  const tableValueStyle = {
    fontSize: "16px",
    fontWeight: "500px",
    color: "black",
    // fontFamily: "Galano Grotesque",
    textAlign: "center",
  };
  const filterOrders = (data, id) => {
    let orderData = [];
    data.map((value) => {
      if (value.sellerId && value.sellerId === id) {
        orderData.push(value);
      }
    });
    return orderData;
  };
  const processSellersInfo = ({ sellers = [], shopName, orders }) => {
    let tempArray = [];
    sellers.map(async (data) => {
      let tempObj = {};
      let filtOrd = filterOrders(orders, data.userId);
      tempObj.shopName = data.shopName;
      tempObj.phone = data.userPhoneNumber;
      tempObj.address = data.shopAddress;
      tempObj.totalOrders = filtOrd;
      tempArray.push(tempObj);
    });
    setCurrentShopName(shopName);
    setSellerDetails([...tempArray]);
    setSellerDetailsModal(true);
  };
  const getTotalOrderValue = (orders, from) => {
    if (orders.length > 0) {
      let totalAmt = 0;
      orders.map((data) => {
        totalAmt += parseFloat(data.totalAmount)
          ? parseFloat(data.totalAmount)
          : 0;
      });
      let average = 0;
      if (parseFloat(totalAmt)) {
        average = totalAmt / orders.length;
      } else {
        average = totalAmt;
      }

      if (from === "total") {
        return totalAmt;
      } else if (from === "average") {
        return average;
      }
    } else {
      return 0;
    }
  };
  const columns = [
    {
      title: <div style={tableHeaderStyle}>SNo</div>,
      key: "index",
      render: (text, record, index) => {
        return <div style={tableValueStyle}>{index + 1}</div>;
      },
    },
    {
      title: <div style={tableHeaderStyle}>Shop name</div>,
      key: "index",
      render: (_, { ShopName }) => {
        return <div style={tableValueStyle}>{ShopName}</div>;
      },
    },
    {
      title: <div style={tableHeaderStyle}>Phone</div>,
      key: "index",
      render: (_, { Phone }) => {
        return <div style={tableValueStyle}>{Phone}</div>;
      },
    },
    {
      title: <div style={tableHeaderStyle}>Address</div>,
      key: "index",
      render: (_, { Address }) => {
        return (
          <div
            style={{ ...tableValueStyle, maxWidth: "250px", margin: "auto" }}
          >
            <ShowMoreText
              lines={2}
              more="see more"
              less="see less"
              className="showMoreBtn"
              // anchorClass="show-more-less-clickable"
              //  onClick={this.executeOnClick}
              expanded={false}
              width={250}
              //  truncatedEndingComponent={"... "}
            >
              {Address}
            </ShowMoreText>
          </div>
        );
      },
    },
    {
      title: <div style={tableHeaderStyle}>Sellers</div>,
      key: "index",
      render: (_, { Sellers = [], ShopName, TotalBillCount }) => {
        return (
          <div
            className={
              Sellers.length > 0 ? "sellersViewBtn" : "sellersViewBtnZero"
            }
            onClick={() => {
              processSellersInfo({
                sellers: [...Sellers],
                shopName: ShopName,
                orders: TotalBillCount,
              });
            }}
          >
            {Sellers.length}
          </div>
        );
      },
    },
    {
      title: <div style={tableHeaderStyle}>Employees</div>,
      key: "index",
      render: (_, { Employees }) => {
        return <div style={tableValueStyle}>{Employees}</div>;
      },
    },
    {
      title: <div style={tableHeaderStyle}>Total orders</div>,
      key: "index",
      render: (_, { TotalBillCount = [] }) => {
        return (
          <div style={tableValueStyle}>
            {TotalBillCount.length > 0 ? TotalBillCount.length : 0}
          </div>
        );
      },
    },
    {
      title: <div style={tableHeaderStyle}>Total order value</div>,
      key: "index",
      render: (_, { TotalBillValue }) => {
        return (
          <div style={tableValueStyle}>
            ₹ {TotalBillValue ? TotalBillValue.toFixed(2) : 0}
          </div>
        );
      },
    },
    {
      title: <div style={tableHeaderStyle}>Average order value</div>,
      key: "index",
      render: (_, { AverageBillValue }) => {
        return <div style={tableValueStyle}>₹ {AverageBillValue || 0}</div>;
      },
    },
  ];

  const sellerColumns = [
    {
      title: <div style={tableHeaderStyle}>SNo</div>,
      key: "index",
      render: (text, record, index) => {
        return <div style={tableValueStyle}>{index + 1}</div>;
      },
    },
    {
      title: <div style={tableHeaderStyle}>Shop name</div>,
      key: "index",
      render: (_, { shopName }) => {
        return <div style={tableValueStyle}>{shopName}</div>;
      },
    },
    {
      title: <div style={tableHeaderStyle}>Phone</div>,
      key: "index",
      render: (_, { phone }) => {
        return <div style={tableValueStyle}>{phone}</div>;
      },
    },
    {
      title: <div style={tableHeaderStyle}>Address</div>,
      key: "index",
      render: (_, { address }) => {
        return (
          <div
            style={{ ...tableValueStyle, maxWidth: "250px", margin: "auto" }}
          >
            <ShowMoreText
              lines={2}
              more="see more"
              less="see less"
              className="showMoreBtn"
              anchorClass="show-more-less-clickable"
              //  onClick={this.executeOnClick}
              expanded={false}
              width={250}
              //  truncatedEndingComponent={"... "}
            >
              {address}
            </ShowMoreText>
          </div>
        );
      },
    },
    {
      title: <div style={tableHeaderStyle}>Total orders</div>,
      key: "index",
      render: (_, { totalOrders = [] }) => {
        return (
          <div style={tableValueStyle}>
            {totalOrders.length > 0 ? totalOrders.length : 0}
          </div>
        );
      },
    },
    {
      title: <div style={tableHeaderStyle}>Total order Value</div>,
      key: "index",
      render: (_, { totalOrders = [] }) => {
        return (
          <div style={tableValueStyle}>
            {totalOrders.length > 0
              ? `₹ ${getTotalOrderValue(totalOrders, "total").toFixed(2)}`
              : 0}
          </div>
        );
      },
    },
    {
      title: <div style={tableHeaderStyle}>Average order Value</div>,
      key: "index",
      render: (_, { totalOrders = [] }) => {
        return (
          <div style={tableValueStyle}>
            {totalOrders.length > 0
              ? `₹ ${getTotalOrderValue(totalOrders, "average").toFixed(2)}`
              : 0}
          </div>
        );
      },
    },
  ];
  const setToDefault = () => {
    setSellerDetailsModal(false);
    setCurrentShopName("");
    setSellerDetails([]);
  };

  function dateProcessor(range) {
    setTempRange({
      ...tempRange,
      startDate: range.selection.startDate,
      endDate: range.selection.endDate,
    });
  }

  const [visiblity, setVisiblity] = useState(false);

  const processDiscard = () => {
    setVisiblity(false);
  };

  const processApply = () => {
    if (
      tempRange.startDate &&
      tempRange.endDate &&
      (tempRange.startDate !== selectionRange.startDate ||
        tempRange.endDate !== selectionRange.endDate)
    ) {
      setSelectionRange({ ...tempRange });
    }
    setVisiblity(false);
  };

  const popoverContent = () => {
    return (
      <div>
        <DateRangePicker ranges={[tempRange]} onChange={dateProcessor} />
        <div className="discardApplyDiv">
          <div className="discardgdi" onClick={() => processDiscard()}>
            Discard
          </div>
          <div className="applygdi" onClick={() => processApply()}>
            Apply
          </div>
        </div>
      </div>
    );
  };
  const getTitle = () => {
    return (
      <div className="titlePopUPdIV">
        <div>Select Range</div>
        <div className="reset">
          <RxReload className="resetIcon" onClick={() => setTempRange({})} />{" "}
          <GrFormClose
            className="dgiClose"
            onClick={() => setVisiblity(false)}
          />
        </div>
      </div>
    );
  };

  const searcher = (text) => {
    if (!text) {
      setInsightDetails([...insightDetailsBackUp]);
      return;
    }
    let options = {
      includeScore: true,
      keys: ["ShopName", "Phone"],
    };
    const fuse = new Fuse(insightDetailsBackUp, options);
    const result = fuse.search(text);
    if (result.length > 0) {
      let temp = result.map((data) => {
        return data.item;
      });
      setInsightDetails([...temp]);
    }
  };
  useEffect(() => {
    getInsight();
  }, [selectionRange]);
  return (
    <div className="ghoDeliveryInsightRoot">
      <div className="headergdiParent">
        <div className="headergdi">GHO DELIVERY INSIGHT</div>
        <div className="searchInputPar">
          <input
            type="search"
            placeholder="Search"
            className="searchInputGDI"
            onChange={(e) => searcher(e.target.value)}
          />
          <HiMagnifyingGlass className="magIcon" />
        </div>
        <div className="selectDatesParent">
          <div className="asdsaas">
            <span
              style={{ cursor: "pointer" }}
              onClick={() => setVisiblity(true)}
            >
              {" "}
              Select Dates{" "}
            </span>
            <span
              onClick={() =>
                setSelectionRange({
                  ...selectionRange,
                  startDate: "",
                  endDate: "",
                })
              }
              className="getAllD"
            >
              Get all data
            </span>
          </div>
          <Popover
            content={popoverContent()}
            title={getTitle()}
            placement="rightTop"
            trigger="click"
            visible={visiblity}
          >
            <div className="" onClick={() => setVisiblity(true)}>
              <div className="fromToDiv">
                {selectionRange.startDate ? (
                  format(selectionRange.startDate, "yyyy-MM-dd")
                ) : (
                  <span style={{ color: "lightgray" }}>Start Date</span>
                )}
                <span style={{ margin: "0px 10px" }}>{"<->"}</span>
                {selectionRange.endDate ? (
                  format(selectionRange.endDate, "yyyy-MM-dd")
                ) : (
                  <span style={{ color: "lightgray" }}>End Date</span>
                )}
              </div>
            </div>
          </Popover>
        </div>
      </div>
      <div className="ghoDeliveryMainInner">
        <div className="ghoDeliveryInsightTC">
          <Table
            dataSource={insightDetails}
            columns={columns}
            pagination={false}
            size="small"
            loading={loading}
          />
        </div>
      </div>
      <Modal
        isOpen={sellerDetailsModal}
        style={sellerDetailsModalStyles}
        onRequestClose={() => setToDefault()}
      >
        <div className="sellerDetailsModalHeader">
          <div>
            <div className="currentShopName">{currentShopName}</div>
            <div className="sellersList">Sellers list</div>
          </div>
          <div onClick={() => setToDefault()} className="sellersModalClose">
            x
          </div>
        </div>
        <Table
          dataSource={sellerDetails}
          columns={sellerColumns}
          pagination={false}
        />
      </Modal>
    </div>
  );
};

export default GhoDeliveryInsight;
