import React from "react";
// Managing Accounts
import AddEmployee from "./employee/addEmployee";
import DeleteAccount from "./component/deleteAccount";
import Mainpage from "./component/mainpage";
import ManagingDirector from "./managingDirector/managingDirector";

// Partner
import PartnerHome from "./partner/home";
import Statement from "./partner/statement";
//License
import LinceseManagement from "./licenseManagement/licenseManagement";
// Error page
import ErrorPage from "./component/errorPage";

// Appointment
import AppointmentShopDetails from "./appointment/appointmentShopDetails";
import AppointmentDashboard from "./appointment/appointmentDashboard";
import ExtendAppointment from "./appointment/extendAppointment";
import ActivateAppointment from "./appointment/activateAppointment";
import AppointmentPackage from "./appointment/appointmentPackageUsers";

//Authentication
import Authentication from "./authentication/auth";

// Website
import GStoreActivation from "./website/gstoreActivation";
import ViewWebsiteStatus from "./website/viewWebsiteStatus";
import OwnDomainTracker from "./website/ownDomainTracker";
import Franchise from "./website/franchise";

//stats
import CouponStatistics from "./component/couponStatistics";
import Dashboard from "./dashboard/dashboard";
import CustomerInsight from "./dashboard/customerinsight";
import GhoDeliveryInsight from "./component/ghoDeliveryInsight";
import PaymentGateways from "./component/paymentGateWays";
import MarketingCampaign from "./component/marketingCampaign";
//Banner
import BannerByLocation from "./banner/bannerByLocation";
import Addbanner from "./component/addBanner";
import Bookedbanner from "./component/bookedBanner";
import MerchantAddBanner from "./component/merchantbanner";

// Delivery
import OnlineOrders from "./deleivery/onlineOrders";
import AddInventoryToShop from "./deleivery/addInventoryToShop";
import DistributionOfCategoryAndSub from "./deleivery/categoryAndSubDistribution";
import OrderDashboard from "./deleivery/orderDashboard";
import OnlineShopStats from "./deleivery/onlineShopStats";
import DeliveryShopDetails from "./deleivery/deliveryShopDetails";
import InventoryProductsByShop from "./deleivery/inventoryProductsByShop";
import ViewShopAddedProduct from "./deleivery/viewShopAddedProduct";
import GetProductsByScan from "./deleivery/getProductsByScan";
import ShopAddedDelivery from "./deleivery/shopAddedDelivery";
import Addproducts from "./deleivery/addProducts";
import ProductUploadThroughExcel from "./deleivery/productUploadThroughExcel";
import DeliveryPaymentTracker from "./deleivery/deliveryPaymentTracker";
import ShopInventoryProducts from "./deleivery/shopInventoryProducts";

// Distribution
import DistributionOfCollectionCategory from "./distributionByPincode/distributionByCollectionCategory";
import MerchantDistribution from "./component/merchantDistribution";
import CustomerDistribution from "./component/customerDistribution";
import OfferDistribution from "./component/offerDistribution";
import CouponDistribution from "./component/couponDistribution";
import ItemDistribution from "./component/itemdistribution";
import GhoshakDistribution from "./component/ghoshakDistribution";
import SegmentAndCustomerDistribution from "./component/segmentAndCustomerDistribution";
import PromocodeDistribution from "./component/promocodeDistribution";
import PromocodeDistributionByPromocode from "./component/promocodeDistributionByPromocode";
import LoyaltyCouponDistributionByPincode from "./component/loyaltyCouponDistributionByPincode";
import MerchantCouponDistributionByPincode from "./component/merchantCouponDistributionByPincode";
import LoyaltyDistribution from "./component/loyaltyDistribution";
import GhoshakDistributionByPincode from "./component/ghoshakDistributionByPincode";

//Management
import CustomerManagement from "./customerManagement/customerManagement";
import ViewCustomerDetails from "./customerManagement/viewCustomerDetails";
import MerchantManagement from "./merchantManagement/merchantManagement";
import ViewMerchantDetails from "./merchantManagement/viewMerchantDetails";
import MerchantIndex from "./merchant/merchantIndex";
import Customerindex from "./customer/customerIndex";

// Reach us
import ReachUsCustomer from "./component/reachUsCustomer";
import ReachUsMerchant from "./component/reachUsMerchant";
import ReachUsInbox from "./reachUs/reachUs";

// Package
import PackageAndInvoice from "./package/package";
import ActiveLoyalty from "./component/activeLoyalty";
import PackageDetails from "./component/package";
import SetLoyaltyRatio from "./component/setLoyaltyRatio";
import Membership from "./component/memberShip";
import ExtendLoyalty from "./component/extendLoyalty";
import ExtendMembership from "./component/extendMembership";
import PackageExpiration from "./component/packageExpiration";
import GhoDeliveryPackage from "./component/ghoDeliveryPackage";

//coupon
import AddCoupons from "./component/addCoupon";
import EditCoupon from "./component/editCoupon";
import DeleteCoupon from "./component/deleteCoupon";
import CouponPayment from "./component/couponPayment";
import CouponRedeemedCustomers from "./component/couponRedeemedCustomer";
import ExtendMerchantCoupon from "./component/extendMerchantCoupon";
import RedeemPromocode from "./component/redeemPromocode";
import ViewOnlineCoupons from "./component/viewOnlineCoupons";
import OnlineCoupon from "./component/onlineCoupon";
import GhoshakTransaction from "./component/ghoshakTransaction";

// offer
import PromoteOffers from "./component/promoteOffers";
import CreateItem from "./component/createCollection";
import ContentReview from "./component/contentReview";
import CreateOffer from "./component/createOffer";
import OffersCreatedByDate from "./component/offersCreatedByDate";
import ExtendOfferEndDate from "./component/extendOfferEndDate";

// Billing
import ActivateBilling from "./billing/activateBilling";
import ActivateGhoDelivery from "./billing/activateGhoDelivery";
import ExtendSMSPackage from "./billing/extendSmsPackage";

import BillingInfo from "./billing/billingInfo";
import ExtendBillingValidity from "./billing/extendBilling";
import BillingProgram from "./billing/billingProgram";
import BillingDashboard from "./billing/billingDashboard";

// TeleCalling

// import TeleCallingDashboard from "./teleCalling/dashboard";
import TeleCallingHome from "./teleCalling/home";
import TelecallingDashboard from "./teleCalling/dashboard";

// Component
import ActiveUsers from "./merchantManagement/activeUsers";
import UserAdded from "./component/userAdded";
import GetReferal from "./referals/referal";
import Mac from "./mac/mac";
import PincodeSynonyms from "./component/pincodeSynonyms";
import AddGhoshakCoins from "./component/addGhoshakCoins";
import SalesTarget from "./component/salesTarget";
import GhoshakGift from "./component/ghoshakGift";
import SalesReferal from "./component/salesRefferal";
import CustomerId from "./component/getCustomerId";
import Notification from "./component/customerNotification";
import CustomerPhoneNumber from "./component/getPhoneNumber";
import ShopCard from "./shopProfile/shopCard";

// gamification
import Gamification from "./gamification";
import Payment from "./payment";
import GamificationHitory from "./gamification/history";

//  CRM
import MerchantCrm from "./crm/merchant/index";
import ContentUpload from "./website/contentUpload";

//Delivery partner
import DeliveryDashboard from "./deliveryPartner/dashboard";
import Resellers from "./component/resellers";
import Reseller from "./component/reseller";

export default [
  {
    Component: Mainpage,
    path: "/",
    roles: [
      "adminstrator",
      "sales",
      "managingDirector",
      "salesManager",
      "dataEntry",
      "partner",
      "viewOffer",
      "teleCalling",
      "franchise",
    ],
  },
  {
    Component: DeliveryDashboard,
    path: "/deliveryPartner",
    roles: [
      "adminstrator",
      "sales",
      "managingDirector",
      "salesManager",
      "dataEntry",
      "partner",
      "viewOffer",
      "teleCalling",
    ],
  },
  {
    Component: ErrorPage,
    path: "/error",
    roles: [
      "adminstrator",
      "sales",
      "managingDirector",
      "salesManager",
      "dataEntry",
      "partner",
      "viewOffer",
      "teleCalling",
    ],
  },
  {
    Component: Dashboard,
    path: "/dashboard",
    roles: ["adminstrator", "managingDirector", "salesManager"],
  },
  {
    Component: CustomerInsight,
    path: "/customerinsight",
    roles: ["adminstrator", "managingDirector", "salesManager"],
  },
  {
    Component: GhoDeliveryInsight,
    path: "/ghoDeliveryInsight",
    roles: ["adminstrator", "managingDirector", "salesManager"],
  },
  {
    Component: PaymentGateways,
    path: "/paymentGateWays",
    roles: ["adminstrator", "managingDirector", "salesManager"],
  },

  {
    Component: MarketingCampaign,
    path: "/marketingCampaign",
    roles: ["adminstrator", "managingDirector", "salesManager"],
  },
  {
    Component: AddEmployee,
    path: "/addemployee",
    roles: ["adminstrator", "sales", "managingDirector", "salesManager"],
  },
  {
    Component: DeleteAccount,
    path: "/deleteAccount",
    roles: ["adminstrator", "salesManager", "managingDirector"],
  },

  {
    Component: ManagingDirector,
    path: "/managingDirector/:pathname",
    roles: ["adminstrator", "managingDirector"],
  },
  // Partner
  {
    Component: PartnerHome,
    path: "/partner/:pathname",
    roles: ["managingDirector", "partner"],
  },
  // {
  //   Component: Statement,
  //   path: "/partner/statement",
  //   roles: ["managingDirector", "partner"],
  // },

  // CRM

  {
    Component: MerchantCrm,
    path: "/merchantcrm",
    roles: ["adminstrator", "managingDirector", "salesManager"],
  },
  {
    Component: Resellers,
    path: "/Resellers",
    roles: ["adminstrator", "managingDirector", "salesManager"],
  },
  {
    Component: Reseller,
    path: "/Resellers/:resellerId",
    roles: ["adminstrator", "managingDirector", "salesManager"],
  },
  {
    Component: LinceseManagement,
    path: "/licensemanagement",
    roles: ["adminstrator", "managingDirector", "salesManager"],
  },
  {
    Component: ShopCard,
    path: "/shopCardDev",
    roles: ["adminstrator", "managingDirector", "salesManager"],
  },
  // Auth

  {
    Component: Authentication,
    path: "/aaaaa",
    roles: ["adminstrator", "sales", "managingDirector", "partner"],
  },

  // Billing

  {
    Component: BillingDashboard,
    path: "/billingDashboard",
    roles: ["adminstrator", "sales", "managingDirector", "salesManager"],
  },
  {
    Component: ActivateBilling,
    path: "/activateBilling",
    roles: ["adminstrator", "sales", "managingDirector", "salesManager"],
  },
  {
    Component: ActivateGhoDelivery,
    path: "/activateGhoDelivery",
    roles: ["adminstrator", "sales", "managingDirector", "salesManager"],
  },
  {
    Component: ExtendSMSPackage,
    path: "/extendSmsPackage",
    roles: ["adminstrator", "sales", "managingDirector", "salesManager"],
  },
  {
    Component: BillingProgram,
    path: "/billingProgram",
    roles: ["adminstrator", "sales", "managingDirector", "salesManager"],
  },

  {
    Component: BillingInfo,
    path: "/info",
    roles: ["adminstrator", "sales", "managingDirector", "salesManager"],
  },
  {
    Component: ExtendBillingValidity,
    path: "/extendBilling",
    roles: ["adminstrator", "sales", "managingDirector", "salesManager"],
  },

  // Offer

  {
    Component: PromoteOffers,
    path: "/promoteoffers",
    roles: ["adminstrator", "sales", "managingDirector"],
  },
  {
    Component: CreateItem,
    path: "/createitem",
    roles: ["adminstrator", "sales", "managingDirector"],
  },

  {
    Component: CreateOffer,
    path: "/createoffer",
    roles: ["adminstrator", "sales", "managingDirector"],
  },
  {
    Component: ContentReview,
    path: "/review",
    roles: ["adminstrator", "sales", "managingDirector"],
  },
  {
    Component: OffersCreatedByDate,
    path: "/offersList",
    roles: ["managingDirector", "viewOffer", "adminstrator", "salesManager"],
  },

  {
    Component: ExtendOfferEndDate,
    path: "/extendOfferEndDate",
    roles: ["adminstrator", "sales", "managingDirector"],
  },

  //coupon
  {
    Component: AddCoupons,
    path: "/addcoupon",
    roles: ["adminstrator", "sales", "managingDirector"],
  },
  {
    Component: EditCoupon,
    path: "/editcoupon",
    roles: ["adminstrator", "sales", "managingDirector"],
  },
  {
    Component: DeleteCoupon,
    path: "/deletecoupon",
    roles: ["adminstrator", "sales", "managingDirector"],
  },

  {
    Component: CouponPayment,
    path: "/couponpayment",
    roles: ["adminstrator", "sales", "managingDirector"],
  },
  {
    Component: CouponRedeemedCustomers,
    path: "/redeemedCustomers",
    roles: ["adminstrator", "sales", "managingDirector", "salesManager"],
  },
  {
    Component: ExtendMerchantCoupon,
    path: "/extendMerchantCoupon",
    roles: ["adminstrator", "sales", "managingDirector"],
  },
  {
    Component: RedeemPromocode,
    path: "/redeemPromo",
    roles: ["adminstrator", "sales", "managingDirector", "salesManager"],
  },
  {
    Component: OnlineCoupon,
    path: "/onlineCoupons",
    roles: ["adminstrator", "sales", "managingDirector", "salesManager"],
  },
  {
    Component: ViewOnlineCoupons,
    path: "/viewOnlineCoupons",
    roles: ["adminstrator", "sales", "managingDirector", "salesManager"],
  },
  {
    Component: GhoshakTransaction,
    path: "/ghoshakTransaction",
    roles: ["adminstrator", "managingDirector", "salesManager"],
  },
  // Appointment
  {
    Component: AppointmentShopDetails,
    path: "/appointment/:Shop",
    roles: ["adminstrator", "sales", "managingDirector", "salesManager"],
  },
  {
    Component: AppointmentPackage,
    path: "/appointmentPackageUsers",
    roles: ["adminstrator", "sales", "managingDirector", "salesManager"],
  },
  {
    Component: AppointmentDashboard,
    path: "/appointmentDashboard",
    roles: ["adminstrator", "sales", "managingDirector", "salesManager"],
  },
  {
    Component: ActivateAppointment,
    path: "/activateAppointment",
    roles: ["adminstrator", "sales", "managingDirector", "salesManager"],
  },
  {
    Component: ExtendAppointment,
    path: "/extendAppointment",
    roles: ["adminstrator", "sales", "managingDirector", "salesManager"],
  },

  //Telecalling

  // {
  //   Component: TeleCallingDashboard,
  //   path: "/telecalling/dashboard",
  //   roles: ["managingDirector", "teleCalling", "adminstrator"],
  // },
  {
    Component: TeleCallingHome,
    path: "/telecallinghome",
    roles: ["managingDirector", "teleCalling", "adminstrator", "salesManager"],
  },
  {
    Component: TelecallingDashboard,
    path: "/telecallingdashboard",
    roles: ["managingDirector", "teleCalling", "adminstrator", "salesManager"],
  },
  // statistics
  {
    Component: CouponStatistics,
    path: "/cstats",
    roles: ["adminstrator", "sales", "managingDirector", "salesManager"],
  },
  // website
  {
    Component: GStoreActivation,
    path: "/gstoreActivation",
    roles: ["adminstrator", "sales", "managingDirector", "salesManager"],
  },
  {
    Component: ViewWebsiteStatus,
    path: "/viewWebsiteStatus",
    roles: ["adminstrator", "sales", "managingDirector", "salesManager"],
  },
  {
    Component: OwnDomainTracker,
    path: "/ownDomainTracker",
    roles: ["adminstrator", "sales", "managingDirector", "salesManager"],
  },
  {
    Component: ContentUpload,
    path: "/contentUpload",
    roles: [
      "adminstrator",
      "sales",
      "managingDirector",
      "salesManager",
      "franchise",
    ],
  },
  {
    Component: Franchise,
    path: "/franchise",
    roles: ["managingDirector"],
  },
  // Banners
  {
    Component: BannerByLocation,
    path: "/getBannerByLocation",
    roles: ["adminstrator", "sales", "managingDirector"],
  },
  {
    Component: Addbanner,
    path: "/addbanner",
    roles: ["adminstrator", "managingDirector"],
  },
  {
    Component: Bookedbanner,
    path: "/editbanner",
    roles: ["adminstrator", "managingDirector"],
  },
  {
    Component: MerchantAddBanner,
    path: "/merchantbanner",
    roles: ["adminstrator", "managingDirector"],
  },
  // Delivery
  {
    Component: OnlineOrders,
    path: "/onlineOrders",
    roles: [
      "adminstrator",
      "sales",
      "managingDirector",
      "dataEntry",
      "salesManager",
    ],
  },
  {
    Component: AddInventoryToShop,
    path: "/addInventory",
    roles: [
      "adminstrator",
      "sales",
      "managingDirector",
      "dataEntry",
      "salesManager",
    ],
  },
  {
    Component: DistributionOfCategoryAndSub,
    path: "/onlineDashboard/:type",
    roles: [
      "adminstrator",
      "sales",
      "managingDirector",
      "dataEntry",
      "salesManager",
    ],
  },
  {
    Component: OrderDashboard,
    path: "/onlineDashboard",
    roles: [
      "adminstrator",
      "sales",
      "managingDirector",
      "dataEntry",
      "salesManager",
    ],
  },
  {
    Component: DeliveryShopDetails,
    path: "/onlineOrders/:shopId",
    roles: [
      "adminstrator",
      "sales",
      "managingDirector",
      "dataEntry",
      "salesManager",
    ],
  },
  {
    Component: OnlineShopStats,
    path: "/shop/:shopId",
    roles: [
      "adminstrator",
      "sales",
      "managingDirector",
      "dataEntry",
      "salesManager",
    ],
  },
  {
    Component: InventoryProductsByShop,
    path: "/inventoryProductsByShop",
    roles: [
      "adminstrator",
      "sales",
      "managingDirector",
      "dataEntry",
      "salesManager",
    ],
  },

  {
    Component: DeliveryPaymentTracker,
    path: "/paymentTracker",
    roles: [
      "adminstrator",
      "sales",
      "managingDirector",
      "dataEntry",
      "salesManager",
    ],
  },
  {
    Component: ShopInventoryProducts,
    path: "/shopInventoryProducts",
    roles: [
      "adminstrator",
      "sales",
      "managingDirector",
      "dataEntry",
      "salesManager",
    ],
  },

  {
    Component: ProductUploadThroughExcel,
    path: "/uploadThroughExcel",
    roles: [
      "adminstrator",
      "sales",
      "managingDirector",
      "dataEntry",
      "salesManager",
    ],
  },
  {
    Component: ViewShopAddedProduct,
    path: "/viewShopAddedProduct",
    roles: [
      "adminstrator",
      "sales",
      "managingDirector",
      "dataEntry",
      "salesManager",
    ],
  },
  {
    Component: ShopAddedDelivery,
    path: "/shopaddeddelivery",
    roles: [
      "adminstrator",
      "sales",
      "managingDirector",
      "dataEntry",
      "salesManager",
    ],
  },
  {
    Component: GetProductsByScan,
    path: "/gpbs",
    roles: [
      "adminstrator",
      "sales",
      "managingDirector",
      "dataEntry",
      "salesManager",
    ],
  },
  {
    Component: Addproducts,
    path: "/addproduct",
    roles: ["adminstrator", "sales", "managingDirector", "dataEntry"],
  },
  // Distribution

  {
    Component: DistributionOfCollectionCategory,
    path: "/distOfCollectionCategory",
    roles: ["adminstrator", "sales", "managingDirector", "salesManager"],
  },
  {
    Component: MerchantDistribution,
    path: "/merchantDistribution",
    roles: ["adminstrator", "sales", "managingDirector", "salesManager"],
  },
  {
    Component: CustomerDistribution,
    path: "/customerDistribution",
    roles: ["salesManager", "adminstrator", "managingDirector"],
  },
  {
    Component: OfferDistribution,
    path: "/offerdistributon",
    roles: ["salesManager", "adminstrator", "managingDirector"],
  },
  {
    Component: CouponDistribution,
    path: "/coupondistribution",
    roles: ["salesManager", "adminstrator", "managingDirector"],
  },
  {
    Component: ItemDistribution,
    path: "/itemdistribution",
    roles: ["salesManager", "adminstrator", "managingDirector"],
  },
  {
    Component: GhoshakDistribution,
    path: "/ghoshak",
    roles: ["salesManager", "adminstrator", "managingDirector"],
  },
  {
    Component: SegmentAndCustomerDistribution,
    path: "/segmentdistribution",
    roles: ["salesManager", "adminstrator", "managingDirector"],
  },
  {
    Component: PromocodeDistribution,
    path: "/promocodedistribution",
    roles: ["salesManager", "adminstrator", "managingDirector"],
  },
  {
    Component: PromocodeDistributionByPromocode,
    path: "/promocode",
    roles: ["salesManager", "adminstrator", "managingDirector"],
  },
  {
    Component: LoyaltyCouponDistributionByPincode,
    path: "/loyaltyCouponDistributionByPincode",
    roles: ["salesManager", "adminstrator", "managingDirector"],
  },
  {
    Component: MerchantCouponDistributionByPincode,
    path: "/merchantCouponDistributionByPincode",
    roles: ["salesManager", "adminstrator", "managingDirector"],
  },
  {
    Component: LoyaltyDistribution,
    path: "/LoyaltyDistribution",
    roles: ["salesManager", "adminstrator", "managingDirector"],
  },
  {
    Component: GhoshakDistributionByPincode,
    path: "/ghoshakCouponByPincode",
    roles: ["salesManager", "adminstrator", "managingDirector"],
  },
  //Management
  {
    Component: Customerindex,
    path: "/customer",
    roles: ["salesManager", "adminstrator", "managingDirector"],
  },
  {
    Component: CustomerManagement,
    path: "/customerManagement",
    roles: ["salesManager", "adminstrator", "managingDirector"],
  },
  {
    Component: ViewCustomerDetails,
    path: "/customerDetails",
    roles: ["salesManager", "adminstrator", "managingDirector"],
  },

  {
    Component: MerchantManagement,
    path: "/merchantManagement",
    roles: ["salesManager", "adminstrator", "managingDirector"],
  },
  {
    Component: ViewMerchantDetails,
    path: "/merchantDetails",
    roles: ["salesManager", "adminstrator", "managingDirector"],
  },
  {
    Component: MerchantIndex,
    path: "/merchant",
    roles: ["salesManager", "adminstrator", "managingDirector"],
  },
  //Reach us

  {
    Component: ReachUsCustomer,
    path: "/reachuscustomer",
    roles: ["adminstrator", "salesManager", "managingDirector"],
  },
  {
    Component: ReachUsMerchant,
    path: "/reachusmerchant",
    roles: ["adminstrator", "salesManager", "managingDirector"],
  },

  {
    Component: ReachUsInbox,
    path: "/reachus",
    roles: ["adminstrator", "salesManager", "managingDirector"],
  },

  //package
  {
    Component: PackageAndInvoice,
    path: "/generateinvoice",
    roles: ["adminstrator", "sales", "managingDirector", "salesManager"],
  },
  {
    Component: PackageDetails,
    path: "/package",
    roles: ["adminstrator", "sales", "managingDirector", "salesManager"],
  },
  {
    Component: ActiveLoyalty,
    path: "/loyalty",
    roles: ["adminstrator", "sales", "managingDirector", "salesManager"],
  },
  {
    Component: SetLoyaltyRatio,
    path: "/setloyalty",
    roles: ["adminstrator", "sales", "managingDirector", "salesManager"],
  },
  {
    Component: Membership,
    path: "/membership",
    roles: ["adminstrator", "sales", "managingDirector", "salesManager"],
  },
  {
    Component: GhoDeliveryPackage,
    path: "/gho-delivery-package",
    roles: ["adminstrator", "sales", "managingDirector", "salesManager"],
  },
  {
    Component: ExtendMembership,
    path: "/extendMembership",
    roles: ["adminstrator", "sales", "managingDirector", "salesManager"],
  },
  {
    Component: ExtendLoyalty,
    path: "/extendLoyalty",
    roles: ["adminstrator", "sales", "managingDirector", "salesManager"],
  },
  {
    Component: PackageExpiration,
    path: "/getExpiration",
    roles: ["adminstrator", "sales", "managingDirector", "salesManager"],
  },
  // component
  {
    Component: ActiveUsers,
    path: "/b2bactiveusers",
    roles: ["adminstrator", "sales", "managingDirector", "salesManager"],
  },
  {
    Component: UserAdded,
    path: "/userAdded",
    roles: [
      "adminstrator",
      "sales",
      "managingDirector",
      "salesManager",
      "teleCalling",
    ],
  },
  {
    Component: GetReferal,
    path: "/referal",
    roles: ["adminstrator", "sales", "managingDirector", "salesManager"],
  },
  {
    Component: Mac,
    path: "/mac",
    roles: ["adminstrator", "managingDirector", "salesManager"],
  },
  {
    Component: PincodeSynonyms,
    path: "/pincodeSynonyms",
    roles: ["adminstrator", "managingDirector", "salesManager"],
  },
  {
    Component: AddGhoshakCoins,
    path: "/addGhoshakCoins",
    roles: ["adminstrator", "managingDirector", "salesManager"],
  },
  {
    Component: SalesTarget,
    path: "/salestarget",
    roles: ["adminstrator", "managingDirector", "salesManager"],
  },
  {
    Component: SalesReferal,
    path: "/salesrefferal",
    roles: ["adminstrator", "managingDirector", "salesManager"],
  },
  {
    Component: GhoshakGift,
    path: "/ghoshakgift",
    roles: ["adminstrator", "managingDirector", "salesManager"],
  },
  {
    Component: CustomerId,
    path: "/getid",
    roles: ["adminstrator", "managingDirector", "salesManager"],
  },
  {
    Component: Notification,
    path: "/notification",
    roles: ["adminstrator", "managingDirector", "salesManager"],
  },
  {
    Component: CustomerPhoneNumber,
    path: "/getnumber",
    roles: ["adminstrator", "managingDirector", "salesManager"],
  },
  // gamification
  {
    Component: Gamification,
    path: "/gamification",
    roles: ["adminstrator", "managingDirector"],
  },
  // GamificationHitory
  {
    Component: GamificationHitory,
    path: "/gamificationHistory",
    roles: ["adminstrator", "managingDirector"],
  },

  {
    Component: Payment,
    path: "/payment",
    roles: ["adminstrator", "managingDirector"],
  },
];
